import React from 'react'

const BusinessAnalysis = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Business Analysis</h3>

        <div className="skills__box">
            <div className="skills__group">

                <div className="skills__data">
                    <i className='bx bxs-badge-check'></i>

                    <div>
                        <h3 className="skills__name__small">Requirement Gathering</h3>
                        <span className="skills__level"> </span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className='bx bxs-badge-check'></i>

                    <div>
                        <h3 className="skills__name__small">Process Improvement </h3>
                        <span className="skills__level"> </span>
                    </div>
                </div>


                <div className="skills__data">
                    <i className='bx bxs-badge-check'></i>

                    <div>
                        <h3 className="skills__name__small">Process Mapping</h3>
                        <span className="skills__level"> </span>
                    </div>
                </div>

            </div>

            <div className="skills__group">

                <div className="skills__data">
                    <i className='bx bxs-badge-check'></i>

                    <div>
                        <h3 className="skills__name__small">Data Analysis</h3>
                        <span className="skills__level"> </span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className='bx bxs-badge-check'></i>

                    <div>
                        <h3 className="skills__name__small">Stakeholder Analysis</h3>
                        <span className="skills__level"> </span>
                    </div>
                </div>


                <div className="skills__data">
                    <i className='bx bxs-badge-check'></i>

                    <div>
                        <h3 className="skills__name__small">Change Management</h3>
                        <span className="skills__level"> </span>
                    </div>
                </div>

            </div>

        </div>

    </div>
  )
}

export default BusinessAnalysis
