import React from 'react'

const Tools = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Tools or Platforms</h3>

        <div className="skills__box">
            <div className="skills__group">

                <div className="skills__data">
                    <i className='bx bxs-badge-check'></i>

                    <div>
                        <h3 className="skills__name">AWS</h3>
                        <span className="skills__level"> </span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className='bx bxs-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Azure </h3>
                        <span className="skills__level"> </span>
                    </div>
                </div>


                <div className="skills__data">
                    <i className='bx bxs-badge-check'></i>

                    <div>
                        <h3 className="skills__name">Docker </h3>
                        <span className="skills__level"> </span>
                    </div>
                </div>
                <div className="skills__data">
                    <i className='bx bxs-badge-check'></i>

                    <div>
                        <h3 className="skills__name">GitHub</h3>
                        <span className="skills__level"> </span>
                    </div>
                </div>

            </div>
            <div className="skills__box">
                <div className="skills__group">

                    <div className="skills__data">
                        <i className='bx bxs-badge-check'></i>

                        <div>
                            <h3 className="skills__name">Excel</h3>
                            <span className="skills__level"> </span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className='bx bxs-badge-check'></i>

                        <div>
                            <h3 className="skills__name">Power BI</h3>
                            <span className="skills__level"> </span>
                        </div>
                    </div>


                    <div className="skills__data">
                        <i className='bx bxs-badge-check'></i>

                        <div>
                            <h3 className="skills__name">CI/CD</h3>
                            <span className="skills__level"> </span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className='bx bxs-badge-check'></i>

                        <div>
                            <h3 className="skills__name">Jira</h3>
                            <span className="skills__level"></span>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </div>
  )
}

export default Tools
